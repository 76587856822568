import React from "react";

import {
  SnackProvider,
  StyleDefaults,
  StyleWrapper,
} from "@backslashbuild/sp-ui";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "./pages/Home";
import MyWallet from "./pages/MyWallet";
import { subscribe, unsubscribe } from "./pubsub";

const App = () => {
  return (
    <>
      <StyleWrapper>
        <StyleDefaults />
        <SnackProvider
          subscribe={(f) => subscribe("notification", f)}
          unsubscribe={(f) => unsubscribe("notification", f)}
        >
          <Router>
            <Switch>
              <Route path="/my-wallet">
                <MyWallet />
              </Route>
              <Route path="/home">
                <Home />
              </Route>
              <Redirect from="*" to="/home" />
            </Switch>
          </Router>
        </SnackProvider>
      </StyleWrapper>
    </>
  );
};

export default App;
