const e = {};

export function publish(name, data) {
  (e[name] = e[name] || new Event(name)).data = data;
  window.dispatchEvent(e[name]);
}
export function subscribe(name, handler) {
  window.addEventListener(name, handler);
}
export function unsubscribe(name, handler) {
  window.removeEventListener(name, handler);
}